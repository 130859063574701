#networkNavMenuTitle {
  border-bottom: none;
  cursor: pointer;
  direction: rtl;
  width: 100%;
  .theme-nav-title-text {
    background-color: $color-primary;
    color: white;
    padding-right: $slide-nav-title-height; 
  }
  .theme-nav-back {
    background-color: $color-primary;
    border-left: 2px solid $color-gray;
    border-right: none;
    color: white;
    transform: rotate(180deg);
    &:hover {
      color: $color-secondary;
    }
  }
}

#networkNavMenuSites {
  direction: rtl;
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  z-index: 101;
  @include flex-box(column);
  transition: left 200ms ease-in-out;
  &, .theme-nav-title { 
    background: white;
  }
  .theme-nav-title { 
    border-top: 1px solid $color-gray;
  }
  .theme-nav-forward {
    border-left: none;
    border-right: 1px solid $color-gray;
    transform: rotate(180deg);
  }
  .network-nav-expanded & { left: 0; }
}
