/* Call to Action Element */

// 1. Custom Styles
.user_mode {
  .custom-cta-layout {
    padding-bottom: 20px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 20px;
    .column {
      padding: 0 1px;
    }
  }
}
.custom-cta {
  .pageElement {
    margin: 1px 0 0 0;
  }
  .sn-call-to-action {
    margin: 0;
    padding: 0;
    height: 0 !important;
    padding-bottom: 56.25%;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(22,40,76,.6);
      opacity: 0;
      z-index: 1;
      transition: opacity $transition-linear;
    }
    &:after {
      background-color: white;
      border: 0;
      height: 5px;
      opacity: 0;
      transition: opacity $transition-linear;
    }
    img {
      filter: grayscale(0);
      transform: scale(1);
      transition: filter $transition-linear, transform $transition-linear;
      object-fit: cover;
    }
    .sn-call-to-action-title {
      font-size: 28px;
      font-weight: 900;
      margin-left: 10px;
      margin-bottom: 15px;
      padding-left: 0;
      position: relative;
      text-align: left;
      transition: padding $transition-linear;
      &:after {
        background: url("../images/icons/arrow_right_gold.svg") 0 0 no-repeat;
        content: '';
        width: 16px;
        height: 16px;
        position: absolute;
        bottom: 5px;
        margin-left: 6px;
        opacity: 0;
        transition: opacity $transition-linear;
      }
    }
    &:hover {
      &:before {
        content: "";
        opacity: 1;
      }
      &:after {
        opacity: 1;
      }
      img {
        filter: grayscale(100%);
        transform: scale(1.05);
      }
      .sn-call-to-action-title {
        padding-left: 10px;
        &:after {
          opacity: 1;
        }
      }
    }
  }
  .sn-call-to-action-overlay {
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.25) 70%, rgba(0,0,0,0.75) 100%);
  }    
  .sn-call-to-action-overlay-text {
    vertical-align: bottom;
  }
  .sn-call-to-action-subtitle {
    display: none;
  }
}
@media only screen and (min-width:728px) and (max-width: 1024px) {
  .custom-cta {
    .sn-call-to-action {
      .sn-call-to-action-title {
        font-size: 18px;
        margin: 0;
        &:after {
          bottom: 2px;
        }
      }
    }
  }
}
@media only screen and (max-width: 727px) {
  .custom-cta {
    .sn-call-to-action {
      .sn-call-to-action-title {
        font-size: 21px;
        &:after {
          bottom: 2px;
        }
      }
    }
    .sn-call-to-action-overlay {
      padding: 10px;
    }
  }
}
