// CUSTOM
.user_mode {
  .sponsor-container {
    .sponsor-container-title {
      margin: 0 0 15px 0 !important;
      flex-basis: 100%;
    }
    .pageEl {
      margin: 20px 30px;
    }
    .column {
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      float: none;
      justify-content: center;
      margin-bottom: 30px;
      margin-top: 30px;
    }
    h3 {
      color: $color-secondary;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 5px;
      line-height: 20px;
    }
    img {
      filter: grayscale(1);
      height: auto;
      max-height: 80px;
      max-width: 170px;
      transition: filter $transition-linear;
      width: auto;
      &:hover {
        filter: grayscale(0);
      }
    }
  }
}
.edit_mode .sponsor-container .heroPhotoElement img { 
  max-width: 150px;
}
@media only screen and (min-width: 1024px) {
  .user_mode {
    .sponsor-container {
      .sponsor-container-title {
        margin: 0 0 20px 0 !important;
      }
      .pageEl {
        margin: 25px 35px;
      }
      .column {
        margin-bottom: 60px;
        margin-top: 60px;
      }
      h3 {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
}