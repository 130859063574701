// Global Footer
// Edit mode UI
.custom-footer-extra {
  @include edit_ui_element($padding: 10px);
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  background-color: $color-primary;
  padding: 10px;
  &:empty{ display: none; }
  .footer-top-container {
    display: flex;
    flex-direction: column;
    width: 95%;
    justify-content: center;
    max-width: $content-area-width;
    padding-bottom: 20px;
    padding-top: 30px;
    border-bottom: 1px solid white;

    .footer-logo {
      display: flex;
      align-self: center;
      max-width: 257px;
      width: auto;
    }
    .footer-social {
      display: flex;
      align-self: center;
      .sn-social-media-list .sn-social-media-icon {
        background: none !important;
        color: white;
        font-size: 2.2em;
        transition: color $transition-linear;
        margin: 0;
        &:hover {
          color: $color-secondary;
          background: none !important;
        }
      }
      
    }
  }
  .footer-bottom-container {
    display: flex;
    flex-flow: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    max-width: $content-area-width;
    padding-bottom: 30px;
    padding-top: 30px;
    .footer-column-1,.footer-column-2,.footer-column-3,.footer-column-4,.footer-column-5 {
      margin-bottom: 15px;
      text-align: center;
    }
    .pageElement > h3:first-child {
      color: white;
      font-size: 12px;
      line-height: 30px;
      text-align: center !important;
      text-transform: uppercase;
    }
    //OVERRIDE OF P
    p {
      margin: 0;
      padding: 0;
    }
    a {
      border-bottom: 1px solid transparent;
      color: white;
      font-family: $font-secondary;
      font-size: 12px;
      font-weight: 400;
      line-height: 30px;
      transition: border $transition-linear;
      &:hover {
        border-bottom: 1px solid $color-secondary;
        color: $color-secondary;
        text-decoration: none;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .custom-footer-extra {
    padding: 40px 40px 35px 40px;
    .footer-top-container {
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 10px;
      padding-top: 0;
      width: 100%;
    }
    .footer-bottom-container {
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: flex-start;
      padding-bottom: 0;
      padding-top: 15px;
      .footer-column-1,.footer-column-2,.footer-column-3,.footer-column-4,.footer-column-5 {
        margin-bottom: 0;
        text-align: left;
      }
      .pageElement > h3:first-child {
        text-align: left !important;
      }
    }
  }
}
