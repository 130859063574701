.logos-slider-container {
  .edit_mode & {
    .heroPhotoElement img {
      max-width: 150px;
    }
  }

  .user_mode & {
    padding-top: 52px;
    padding-bottom: 26px;
    .column-1 {
      .pageEl {
        position: relative;
        &:active, &:focus, &:visited {
          border: none;
          outline: none;
        }
      }
      .pageEl:after {
        content: "";
        width: 1px;
        background-color: $color-gray-lt;
        height: 192px;
        top: calc(50% - 96px);
        left: 0;
        position: absolute;
        display: block;
      }
      .slick-current {
        .pageEl:after {
          width: 0;
        }
      }
      .heroPhotoElement {
        display: block;
        filter: grayscale(0);
        transition: filter $transition-linear, transform $transition-linear;
        &:hover {
          filter: grayscale(0);
        }
        a {
          display: block;
          padding-bottom: 30px;
          position: relative;
          z-index: 20;
          &:active, &:focus, &:visited {
            border: none;
            outline: none;
          }
        }
        img {
          max-width: 182px;
        }
        .cutline {
          font-family: $font-primary;
          font-size: 15px;
          font-weight: 500;
          color: $color-secondary;
          background: transparent;
          font-style: normal;
          text-transform: uppercase;
          text-align: center;
          padding: 0;
          margin-top: -42px;
          position: relative;
          z-index: 20;
        }
      }
      &:hover {
        .heroPhotoElement {
          filter: grayscale(1);
          &:hover {
            filter: grayscale(0) !important;
            transform: translateY(-10px);
          }
        }
      }
    }
    .slick-slider {
      .slick-arrow {
        height: 192px;
        background-color: white;
        z-index: 1;
      }
      .slick-prev:before,
      .slick-next:before {
        font-family: FontAwesome;
        color: #ddd;
        font-size: 40px;
        font-weight: normal;
        transition: color $transition-linear;
      }
      .slick-next {
        &:before {
          content: "\f105";
        }
      }
      .slick-prev {
        &:before {
          content: "\f104";
        }
      }
      &:hover {
        .slick-prev:before,
        .slick-next:before {
          color: $color-secondary;
        }
      }
    }
    @media screen and (max-width: 1024px) {
      .user_mode & {
        padding-bottom: 52px;
        .column-1:hover .heroPhotoElement {
          filter: grayscale(0);
        }
      }
    }
    @media screen and (max-width: 1260px) {
      .slick-slider {
        .slick-next {
          right: 10px;
        }
        .slick-prev {
          left: 10px;
        }
      }
    }
  }
}
