.user_mode {
  .hero-slider-container {
    padding: 0;
    .column {
      padding: 0;
    }
    .mediaSlider {
      h2, p {
        display: none;
      }
    }
    .pageElement {
      margin: 0;
    }
    .slider.bottom-center-paging {
      margin: 0;
    }
  }
}

.hero-slider-container {
  .sn-media-slider {
    .slider {
      font-family: $font-primary;
      .slide-title {
        font-size: 48px;
        font-weight: 800;
        text-shadow: none;
        text-align: center;
      }
      .slide-description {
        font-size: 18px;
        font-weight: 500;
        text-shadow: none;
        text-align: center;
      }
      &.bottom-center-paging {
        .slider-pagination {
          bottom: 60px;
          height: 5px;
        }
      }
    }
    .slides .slide .slide-overlay {
      display: flex;
      padding: 5% 10%;
    }
    .slide {
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0) 50%,
          rgba(0, 0, 0, 0.5) 100%
        );
        z-index: 1;
      }
    }

    .slide-text {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: flex-end;
      padding-bottom: 50px;
      .slide-title {
        line-height: 1.125;
        margin: 0 0 15px 0;
      }
      .slide-description {
        line-height: 1.125;
        margin: 0 0 30px 0;
      }
    }

    .slider-pagination.dashed {
      .paging-item {
        width: 70px;
        height: 5px;
        background-color: $color-gray-lt;
        margin: 0 4px;
        border-radius: 5px;
        &.flex-active {
          background-color: $color-secondary;
        }
      }
    }
    .flex-direction-nav {

      .flex-prev {
        left: 5%;
      }
      .flex-next {
        right: 5%;
      }
      a {
        width: 80px;
        height: 80px;
        opacity: .3;

        &:before {
          content: url(../images/icons/arrow_large_left.svg);
          display: grid;
          place-items: center;
          width: 100%;
          height: 100%;
          box-shadow: none;
          text-shadow: none;
          transition: filter $transition-linear;
        }
        &:hover {
          opacity: 1;
          &:before {
            filter: invert(25%) sepia(41%) saturate(7335%) hue-rotate(25deg)
              brightness(100%) contrast(91%);
            text-shadow: none;
            box-shadow: none;
          }
        }
        &.flex-next {
          &:before {
            transform: rotate(180deg) translateY(4px);
          }
        }
      }
    }
  }
  .button-medium {
    background-color: rgba($color-secondary, 0);
    border: 1px solid white;
    transition: border $transition-linear, background-color $transition-linear;
    &:hover {
      background-color: rgba($color-secondary, 1);
      border-color: $color-secondary;
    }
  }
}

@media screen and (max-width: 900px) {
  .hero-slider-container {
    .sn-media-slider {
      .slider {
        font-family: $font-primary;
        .slide-title {
          font-size: 21px;
        }
        .slide-description {
          display: none;
        }
        &.bottom-center-paging {
          .slider-pagination {
            bottom: 25px;
          }
        }
      }
      .slide-text {
        padding-bottom: 20px;
      }
      .slider-pagination.dashed {
        .paging-item {
          width: 40px;
          height: 3px;
          margin: 0 2px;
        }
      }
      .flex-direction-nav {
        opacity: 0;
      }
    }
    .button-medium {
      font-size: 12px;
      padding: 7px 15px;
    }
  }
}
