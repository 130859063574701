// CUSTOM
.user_mode {
  .video-container {
    background-color: $color-primary;
    background-position: bottom left;
    background-size: cover;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 30px;
  }
  .video {
    margin: 0 auto;
    max-width: 900px;
    width: auto;
  }
  .video-button {
    background: transparent;
    .linkElement {
      display: flex;
      justify-content: center;
      h4 a {
        background: none;
        background-color: transparent;
        transition: background-color 0.2s linear, border 0.2s linear;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 12px;
        justify-content: center;
        color: #ffffff;
        border: 1px solid white;
        border-radius: 4px;
        padding: 12px;
        width: auto;
        height: auto;
        &:before, &:after {
          content: none;
        }
        &:hover {
          background-color: $color-secondary;
          border: 1px solid $color-secondary;
        }
      }
    }
  }
  .video-container-background-image {
   display: none;
  }
}
@media only screen and (min-width: 1024px) {
  .user_mode {
    .video-container {
      background-size: auto;
      padding-bottom: 50px;
      padding-right: 0;
      padding-left: 0;
      padding-top: 50px;
    }
    .video-button {
      background: transparent;
      .linkElement {
        display: flex;
        justify-content: center;
        h4 a {
          padding: 15px 22px;
        }
      }
    }
  }
}