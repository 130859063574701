// From Theme
.pageEl {
  //jwplayer
  .video .jwplayer {
    width: 100% !important;
    height: 100% !important;
  }
  .jwplayer {
    width: 100% !important;
  }
}