// CUSTOM
.user_mode {
  .quick-links {
    .pageElement {
      margin-top: 1px;
      margin-bottom: 1px;
    }
    .linkElement h4 a {
      padding-left: 20px;
      transition: padding-left $transition-linear;
      &:focus, &:hover {
        padding-left: 30px;
      }
    }
    .linkElement .goToLink {
      &:after {
        right: 20px;
        transition: right $transition-linear;
      }
      &:focus, &:hover {
        &:after {
          right: 30px;
        }
      }
    }
  }
  .scroll-up {
    position: relative;
  }
  a.scrollup, a.scrollup:focus, a.scrollup:hover {
    position: fixed;
    opacity: 0;
    right: 20px;
    bottom: 14px;
    color: #fff;
    line-height: 1;
    z-index: 10;
    padding: 5px 10px;
    background: $color-primary none repeat scroll 0 0;
    border-radius: 2px;
    border: 1px solid white;
    transition: opacity $transition-linear;
    &:before {
      content: "\f106";
      font-family: FontAwesome;
      font-size: 20px;
      font-size: 2rem;
    }
  }
}