// CUSTOM
.user_mode {
  .social-container {
    background-color: $color-secondary;
    background-position: top center;
    background-size: cover;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 30px;
    .column {
      margin-bottom: 20px;
    }
    .pageElement > h3:first-child {
      font-size: 21px;
      font-weight: 900;
    }
    .hrElement {
      background-color: white;
      height: 2px;
    }
  }
  .social-instagram {
    .codeElement iframe {
      min-width: 0 !important;
    }
  }
  .social-facebook {
    height: 500px !important;
  }
  .social-news-aggregator {
    background-color: white;
    padding: 15px 20px;
    .newsAggregatorElement .condensed, .newsAggregatorElement .expanded {
      border-bottom: 1px solid #DDD;
      padding-bottom: 15px;
      padding-top: 15px;
      &:nth-child(1){
        padding-top: 0;
      }
      &:nth-child(3){
        border-bottom: none;
        padding-bottom: 0;
      }
    }
    .newsAggregatorElement {
      ul li {
        h4 {
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
        }
        a {
          color: $color-primary;
          font-weight: 500;
          transition: color $transition-linear;
          &:hover {
            color: $color-secondary;
          }
        }
      }
      .dateAuthor, .commentsAndMore, .extendedOptions {
        display: none;
      }
    }
  }
  .social-link {
    .pageElement {
      margin-top: 1px;
      margin-bottom: 1px;
    }
    .linkElement h4 a {
      padding-left: 20px;
      transition: padding-left $transition-linear;
      &:focus, &:hover {
        padding-left: 30px;
      }
    }
    .linkElement .goToLink {
      &:after {
        right: 20px;
        transition: right $transition-linear;
      }
      &:focus, &:hover {
        &:after {
          right: 30px;
        }
      }
    }
  }
  .social-container-background-image {
    display: none;
   }
}
@media only screen and (min-width: 1024px) {
  .user_mode {
    .social-container {
      padding-bottom: 45px;
      padding-right: 0;
      padding-left: 0;
      padding-top: 55px;
      .column {
        margin-bottom: 0;
      }
      .pageElement > h3:first-child {
        font-size: 24px;
        margin-top: 0;
      }
    }
    .social-instagram, .social-facebook {
      .pageElement {
        margin-top: 0;
      }
    }
    .social-instagram {
      .codeElement iframe {
        min-width: inherit !important;
      }
    }
  }
}