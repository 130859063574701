// CUSTOM
#displayBodyHeader.displayBodyHeader-image {
  padding-bottom: 230px;
  position: relative;
  max-height: 230px;
  max-width: 1140px;
  width: 100%;
  span {
    border-bottom: none;
    bottom: 50px;
    color: white;
    display: block;
    left: 30px;
    font-family: $font-primary;
    font-size: 30px;
    font-weight: 900;
    line-height: 1;
    padding: 0;
    position: absolute;
    text-transform: uppercase;
    z-index: 1;
  }
}
.displayBodyHeader-image {
  text-indent: 0 !important;
  &:after {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.25) 70%, rgba(0, 0, 0, 0.75) 100%);
    bottom: 0;
    content: '';
    left: 0;
    height: 100%;
    right: 0;
    position: absolute;
    width: 100%;
    top: 0;
  }
}
body:not(.home).user_mode.header_image { 
  .yieldPageContent {
    .layoutContainer {
      &:first-of-type {
        padding-top: 30px;
      }
    }
  }
  .overview-text-section {
    .pageElement > h3 {
      margin-bottom: 20px;
    }
  }
  .gallery_info {
    display: none;
  }
  .sidebar-title-text {
    .pageElement > h3 {
      font-size: 24px;
      font-weight: 900;
      line-height: 30px;
    }
  }
  .related-links {
    margin-top: 15px;
  }
  .event-aggregator {
    margin-top: 25px;
    .dateImage, .extendedOptions a {
      &:after {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, .10) 70%, rgba(255, 255, 255, 0) 100%);
        bottom: 0;
        content: '';
        left: 0;
        height: 100%;
        right: 0;
        position: absolute;
        width: 100%;
        top: 0;
      }
    }
    .dateImage {
      &:after {
        max-width: 75px;
        min-height: 100px;
      }
    }
    .dateImage {
      .month, .date {
        z-index: 1;
      }
    }
    .extendedOptions {
      color: transparent;
      a {
        background-color: $color-primary;
        color: white;
        margin-bottom: 10px;
        padding: 5px 10px;
        &:before {
          color: white;
        }
        &:hover {
          color: $color-secondary;
          &:before {
            color: $color-secondary;
          }
        }
      }
    }
  }
  .aggHeader {
    font-size: 24px;
    font-weight: 900;
    line-height: 30px;
  }
  hr {
    background-color: $color-secondary;
    height: 2px;
    margin-bottom: 2rem;
    margin-top: 4rem;
  }
  .custom-cta-layout {
    margin-bottom: 30px;
    margin-top: 0;
    padding-bottom: 60px;
  }
}
@media only screen and (min-width: 1024px) {
  #displayBodyHeader {
    span {
      font-size: 42px;
      left: 30px;
    }
  }
  body:not(.home).user_mode.header_image {
    .yieldPageContent {
      .layoutContainer {
        &:first-of-type {
          padding-top: 70px;
        }
      }
    }
    .related-links {
      margin-top: 40px;
    }
    .event-aggregator {
      margin-top: 60px;
    }
    .custom-cta-layout {
      padding-bottom: 160px;
    }
  }
}